import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employesUnderline, jorttLogo, jorttScreenshot } from 'images/koppelingen'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

const dummyKlantVerhaal = {
  content: [{
    title: 'Salarisadministratie met Employes & Jortt',
    description: 'Employes is een salarispakket met een gebruiksvriendelijke interface, speciaal ontwikkeld voor ondernemers. Door de duidelijke stappenplannen en overzichtelijke schermen, hebben wij de manier van gegevens invoeren in ons systeem zó makkelijk gemaakt, dat jijzelf je salarisadministratie foutloos door onze software kan laten doen.'
    }, {
      title: 'Koppel je boekhouding',
      description: 'Na het doen van een verloning, dienen de kosten van je personeel verwerkt te worden in Jortt. Dat kun je doen door handmatig een boeking te maken in Jortt. Maar het is natuurlijk veel handiger om dit automatisch te laten verlopen. Daarvoor kun je gebruik maken van de koppeling tussen Employes en Jortt. Hiermee kun je de journaalposten na iedere verloning met een druk op de knop versturen.'
    }, {
      image: jorttScreenshot
    }, {
        title: 'Tarieven van de koppeling',
        description: 'Het gebruik van de koppeling is geheel gratis als je klant bent bij Employes. Ben je benieuwd hoe het werkt? Maak dan geheel vrijblijvend een proefaccount aan.'
  }]
}

class Jortt extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Jortt & Employes | Salarisadministratie</title>
            <meta name="description" content="Automatisch je journaalposten importeren in je Jortt boekhouding met de koppeling van Employes."/>
            <meta itemprop="name" content="Koppeling Jortt & Employes | Salarisadministratie" />
            <meta itemprop="description" content="Automatisch je journaalposten importeren in je Jortt boekhouding met de koppeling van Employes."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling jortt padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Jortt aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline} alt="logo employes"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={jorttLogo} alt="afbeelding jortt"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const { title, description } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                <p>{ description}</p>
                              </div>
                            ),
                            image: (
                              <img src={section.image}/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>

                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Jortt.propTypes = {
  data: PropTypes.object
}

export default Jortt
